import {message} from 'ant-design-vue';
import axios from "axios";
import * as XLSX from "xlsx/xlsx.mjs";
/* 加载'fs'的readFile和writeFile支持 */
import * as fs from "fs";

XLSX.set_fs(fs);

// 自定义函数解析CSV行
function parseCSVLine(line) {
    const regex = /("([^"]|"")*"|[^,]+)/g;
    const matches = line.match(regex);
    return matches ? matches.map(match => match.replace(/^"|"$/g, '').replace(/""/g, '"').trim()) : [];
}

/**
 * 异步加载并解析 csv 文件
 * @param {string} url
 * @param {Function} callback
 * @param emptyFiled
 */
export function csvRead(url, callback,emptyFiled = "__EMPTY") {
    // axios.get(url)
    //     .then((res) => {
    //         let lines = res.data.split("\n");
    //         const result = [];
    //         const headers = lines[0].split(",");
    //
    //         headers.forEach((item, index) => {
    //             if (item === "") {
    //                 headers[index] = emptyFiled;
    //             }
    //         })
    //
    //         for (let i = 1; i < lines.length; i++) {
    //             const obj = {};
    //             const currentLine = lines[i].split(",");
    //
    //             for (let j = 0; j < headers.length; j++) {
    //                 obj[headers[j]] = currentLine[j];
    //             }
    //
    //             result.push(obj);
    //         }
    //
    //         //回调函数
    //         callback(result);
    //     }).catch(err => {
    //     callback([]);
    // })


    axios.get(url)
        .then((res) => {
            let lines = res.data.split("\n");
            const result = [];
            const headers = lines[0].split(",").map(item => item.replace(/^"|"$/g, '').trim() === "" ? emptyFiled : item.replace(/^"|"$/g, '').trim());

            console.log(headers)

            // 处理每一行数据
            for (let i = 1; i < lines.length; i++) {
                const obj = {};
                const currentLine = parseCSVLine(lines[i]);

                for (let j = 0; j < headers.length; j++) {
                    obj[headers[j]] = currentLine[j] !== undefined ? currentLine[j] : "";
                }

                result.push(obj);
            }

            // 回调函数
            callback(result);
        }).catch(err => {
        callback([]);
    });
}

/**
 * 异步加载并解析 csv 文件
 * @param {string} url
 * @param {Function} callback
 * @param option
 * @return {*}
 */
export function parseCsv(url, callback, option = {}) {
    axios.get(url, {responseType: 'arraybuffer'})
        .then((res) => {
            const data = new Uint8Array(res.data)

            let workbook = XLSX.read(data, {type: "array", ...option});

            const sheetNames = workbook.SheetNames; //获取表名
            let sheet = workbook.Sheets[sheetNames[0]]; //通过表名得到表对象

            const csvData = XLSX.utils.sheet_to_json(sheet);

            //回调函数
            callback(csvData);
        }).catch(err => {
    })
}

/**
 * 同步加载并解析 csv 文件
 * @param {string} url
 * @param {Function} callback
 * @return {*}
 */
export async function parseCsvAsync(url, callback) {
    await axios.get(url, {responseType: 'arraybuffer'})
        .then((res) => {
            const data = new Uint8Array(res.data)

            let workbook = XLSX.read(data, {type: "array"});

            const sheetNames = workbook.SheetNames; //获取表名
            let sheet = workbook.Sheets[sheetNames[0]]; //通过表名得到表对象
            const csvData = XLSX.utils.sheet_to_json(sheet);

            //回调函数
            callback(csvData);
        }).catch(err => {
        })
}

/**
 * 下载文件至本地
 * @param {string} url
 */
export function downloadFile(url) {
    if (url === "") {
        message.error('下载错误，文件地址不能为空！')
        return;
    }

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', url.split("/")[url.split("/").length - 1] || 'file');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

/**
 * 下载自定义表头数据Csv文件
 * @param csvHead 表头字符串 Fe:`用户名,时间,坐标,来源,授权时间\n`
 * @param data 表格数据 二维数组
 * @param fileName 文件名称
 */
export function downLoadCsv(csvHead, data, fileName = '表格数据') {
    //增加\t为了不让表格显示科学计数法或者其他格式
    for (let i = 0; i < data.length; i++) {
        for (let item in data[i]) {
            csvHead += `${data[i][item] + '\t'},`;
        }
        csvHead += '\n';
    }
    //encodeURIComponent解决中文乱码
    let uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(csvHead);
    //通过创建a标签实现
    let link = document.createElement("a");
    link.href = uri;
    //对下载的文件命名
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}